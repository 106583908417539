import React from "react";
import { Helmet } from "react-helmet";
import "../styles/index.scss";

const Layout = ({ children }) => (
  <>
    <Helmet
      title="Haitians Who Code"
      meta={[
        {
          property: "description",
          content:
            "A Twitter directory of accomplished women in the design industry.",
        },
        { property: "og:title", content: "Haitians Who Code" },
        {
          property: "og:description",
          content:
            "Haitians in engineering, product design, design systems and web development.",
        },

        {
          property: "og:image",
          content: "https://haitian.dev/opengraph.png",
        },
        {
          property: "og:url",
          content: "https://haitian.dev",
        },
        { property: "og:type", content: "website" },
        { property: "og:site_name", content: "Haitians Who Code" },
        { property: "twitter:site", content: "@devhaiti" },
        { property: "twitter:creator", content: "@_darkfadr" },
        { property: "twitter:card", content: "summary_large_image" },
        {
          property: "twitter:image",
          content: "https://haitian.dev/opengraph.png",
        },
      ]}
    />
    {children}
  </>
);

export default Layout;
